<!--
 * @Description:
 * @Author: Rabbiter
 * @Date: 2023-03-04 15:31:03
-->
<template>
    <div
        style="
            text-align: center;
            height: 100%;
            padding: 20px 0 0 0;
            margin: 0px;
        "
    >
    <div class="userInfo">
        <b> 当前登录信息</b>
    </div>

        <el-descriptions
            title=""
            :column="1"
            size="40"
            border
            style="padding: 20px 0 20px 0"
        >
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-s-custom"></i>
                    账号
                </template>

                <el-tag type="info">{{ user.no }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-user"></i>
                    学号
                </template>
                <el-tag type="info">{{ info.infoSid}}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-mobile-phone"></i>
                    电话
                </template>
                <el-tag type="info">{{ info.infoPhone }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-location-outline"></i>
                    性别
                </template>
                <el-tag
                    :type="info.infoSex === '男' ? 'primary' : 'danger'"
                    disable-transitions
                    ><i
                        :class="
                            info.infoSex === '男' ? 'el-icon-male' : 'el-icon-female'
                        "
                    ></i
                    >{{ info.infoSex }}</el-tag
                >
            </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              学院
            </template>
            <el-tag type="info">{{ info.infoCollege}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              专业
            </template>
            <el-tag type="info">{{ info.infoClass}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              公寓名称
            </template>
            <el-tag type="info">{{ info.infoDorm}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              现住宿舍号
            </template>
            <el-tag type="info">{{ info.infoDormid}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              家长姓名
            </template>
            <el-tag type="info">{{ info.infoPname}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              家长手机号
            </template>
            <el-tag type="info">{{ info.infoPphone}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              班主任姓名
            </template>
            <el-tag type="info">{{ info.infoMname}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              班主任所属
            </template>
            <el-tag type="info">{{ info.infoMclass}}</el-tag>
          </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="iconfont icon-r-mark1"></i>
                    角色
                </template>
                <el-tag type="success" disable-transitions>{{
                    user.roleId == 0
                        ? "超级管理员"
                        : user.roleId == 1
                        ? "管理员"
                        : "用户"
                }}</el-tag>
            </el-descriptions-item>
        </el-descriptions>
        <hr />
        <el-row style="text-align: left">
            <el-button
                type="danger"
                @click="logout"
                style="margin: 25px 0 0 90px; font-size: 22px;"
                >
                 退出登录</el-button
            >
          <el-button
              type="danger"
              @click="changeInfo"
              style="margin: 25px 0 0 90px; font-size: 22px; float: right;"
          >
            修改信息</el-button
          >
        </el-row>
    </div>
</template>

<script>
import changeInfo from "@/components/ChangeInfo.vue";
import ChangeInfo from "@/components/ChangeInfo.vue";

let info = {};
export default {
    name: "Home",
    data() {
        return {
            user: {},
            info: {},
        };
    },
    computed: {

    },
    methods: {
      changeInfo() {
        this.$router.push({ path: '/changeInfo', query: { id: this.user.id ,name:  this.user.no  } });
      },
        init() {
            this.user = JSON.parse(sessionStorage.getItem("CurUser"));
          this.info = JSON.parse(sessionStorage.getItem("userInfo"));
        },
        logout() {
            this.$confirm("您确定要退出登录吗？", "提示", {
                confirmButtonText: "确定", //确认按钮的文字显示
                type: "warning",
                center: true, //文字居中显示
            })
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "退出登录成功！",
                    });

                    this.$router.push("/");
                    sessionStorage.clear();
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消退出登录！",
                    });
                });
        },
    },
    created() {
        this.init();

    },
};
</script>

<style scoped>
.el-descriptions {
    width: 90%;

    margin: 0 auto;
    text-align: center;
}

.userInfo {
    font-size: 32px;
}
</style>
